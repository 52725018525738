import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Row, Space, Table, Typography } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Account } from "@/models/vendor-central-credential";
import { useObservable } from "@/utils/use-observable";
import Loader from "@app/components/loader";
import "./index.less";
import { ColumnsType } from "antd/lib/table";
import { apiDateFormat, apiDateTimeFormat, dateFormat, renderBoolean, renderDate, renderPercentage } from "@/utils";
import { authService, vendorCentralCredentialService } from "@/services";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";

const { Title, Text } = Typography;

const columns: ColumnsType<Account> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "250px",
  },
  {
    title: "Payee Code",
    dataIndex: "payeeCode",
    key: "payeeCode",
    width: "100px",
  },
  {
    title: "Marketplace",
    dataIndex: "marketplace",
    key: "marketplace",
    width: "100px",
    align: "center",
  },
  {
    title: "Disabled",
    dataIndex: "disabled",
    key: "disabled",
    width: "80px",
    align: "center",
    render: renderBoolean,
  },
  {
    title: "Start Date",
    dataIndex: "validityStartDate",
    key: "validityStartDate",
    width: "90px",
    render: renderDate,
  },
  {
    title: "Disabled Dispute Invoice",
    dataIndex: "disabledDisputeInvoice",
    key: "disabledDisputeInvoice",
    width: "100px",
    align: "center",
    render: renderBoolean,
  },
  {
    title: "Dispute Invoice StartDate",
    dataIndex: "disputeInvoiceStartDate",
    key: "disputeInvoiceStartDate",
    width: "120px",
    render: renderDate,
  },
  {
    title: "Dispute Invoice End Date",
    dataIndex: "disputeInvoiceEndDate",
    key: "disputeInvoiceEndDate",
    width: "120px",
    render: renderDate,
  },
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
    width: "70px",
    render: renderPercentage,
  },
  {
    title: "Updating",
    dataIndex: "updating",
    key: "updating",
    width: "80px",
    align: "center",
    render: renderBoolean,
  },
  {
    title: "Last Update",
    dataIndex: "lastUpdate",
    key: "lastUpdate",
    width: "100px",
    render: renderDate,
  }
];

export default function VendorCentralCredentialPage() {
  const [form] = Form.useForm();
  const isFetching = useObservable(
    vendorCentralCredentialService.isFetchingOne
  );
  const vendorCentralCredential = useObservable(
    vendorCentralCredentialService.vendorCentralCredential
  );
  const vendorCentralCredentialId = useParams().id!!;
  const [t] = useTranslation();
  const admin = useObservable(authService.isAdmin);
  const isUpdating = useObservable(vendorCentralCredentialService.isUpdating);
  const isDeleting = useObservable(vendorCentralCredentialService.isDeleting);
  const router = useNavigate();
  const [accounts, setAccounts] = useState<Account[] | undefined>(vendorCentralCredential?.accounts);

  useEffect(() => {
    vendorCentralCredentialService.getCredential(vendorCentralCredentialId).then(() => {
      setAccounts(vendorCentralCredentialService.vendorCentralCredential.getValue()?.accounts)
    });

    return () => {
      vendorCentralCredentialService.disposeCredential();
    };
  }, [vendorCentralCredentialId]);


  const onSubmit = ({
    email,
    password,
    otpSecret,
    accounts,
  }: {
    email: string;
    password: string;
    otpSecret: string;
    accounts: Account[];
  }) => {
    vendorCentralCredentialService
      .updateCredential(vendorCentralCredentialId, email, password, otpSecret, accounts)
      .then((response) => {
        if (response) {
          message.success("Credential updated", 4);
        }
      });
  };

  const onDelete = () => {
    vendorCentralCredentialService
      .deleteCredential(vendorCentralCredentialId)
      .then((response) => {
        if (response) {
          message.success("Credential deleted", 4);
          router("/vendor-central-credentials");
        }
      });
  };

  const onAddAccount = async (account: Account) => {
    const newAccounts = [...accounts!!, Object.assign(new Account(), account)];
    form.setFieldValue("accounts", newAccounts);
    setAccounts(newAccounts);
  }

  const onEditAccount = async (account: Account) => {
    const currentAccounts = accounts!!;
    const currentAccount = currentAccounts.find((a) => a.name === account.name && a.payeeCode === account.payeeCode)!!;
    Object.assign(currentAccount, account);
    form.setFieldValue("accounts", currentAccounts);
    setAccounts([...currentAccounts]);
  }

  if (!vendorCentralCredential || !accounts || isFetching) {
    return <Loader />;
  }

  return (
    <Row className="vendor-central-credential-page">
      <Col flex={1}>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row justify="center">
            <div style={{ width: "900px" }}>
              <Row justify="center">
                <Title level={3}>{vendorCentralCredential.email}</Title>
              </Row>
              <Divider />

              <Form.Item
                initialValue={vendorCentralCredential.email}
                label="Email"
                name="email"
                rules={[{ required: true, message: t("error.fieldRequired") }]}
              >
                <Input />
              </Form.Item>
              {admin && <div>
                <Form.Item
                  label="New Password"
                  name="password"
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="New Otp Secret"
                  name="otpSecret"
                >
                  <Input.Password />
                </Form.Item>
              </div>}
            </div>
          </Row>
          <center>
            <div style={{ maxWidth: "900px" }}>
              <Form.Item
                label={
                  <Space>
                    {admin && <AddAccountButton onSubmit={onAddAccount} />}
                    <Text>Accounts</Text>
                  </Space>

                }
                name="accounts"
                initialValue={vendorCentralCredential.accounts}
              >
                <Table
                  style={{ maxWidth: 900 }}
                  dataSource={accounts}
                  pagination={false}
                  columns={
                    admin ?
                      [...columns, {
                        title: "Actions",
                        dataIndex: "actions",
                        key: "action",
                        width: "80px",
                        align: "center",
                        render: (_: any, account: Account) => <Button
                          type="primary"
                          size="small"
                          shape="circle"
                          icon={<EditAccountButton account={account} onSubmit={onEditAccount} />}

                        />,
                      }] :
                      columns}
                  rowKey="name"
                  size="small"
                  scroll={{ x: 900, y: 500 }}
                />
              </Form.Item>
            </div>
          </center>
          {admin &&
            <Row justify="center">
              <div style={{ width: "900px" }}>
                <Row gutter={[16, 16]} justify="space-around">
                  <Col sm={12} xs={24} >
                    <Button type="primary"
                      block
                      loading={isUpdating}
                      htmlType="submit">
                      Save
                    </Button>
                  </Col>
                  <Col sm={12} xs={24} >
                    <Space />
                    <Button
                      type="primary"
                      block
                      danger
                      loading={isDeleting}
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          }
        </Form>
      </Col>
    </Row>
  );
}


function AddAccountButton(props: {
  onSubmit: (account: Account) => void;
}) {
  const [newAccountVisible, setNewAccountVisible] = useState(false);

  const showNewAccountModal = () => {
    setNewAccountVisible(true);
  };

  const hideNewAccountModal = () => {
    setNewAccountVisible(false);
  };

  const onSubmit = (account: Account) => {
    props.onSubmit(account);
    hideNewAccountModal();
  }

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={showNewAccountModal}
      />
      <AccountNewForm
        visible={newAccountVisible}
        onFinish={hideNewAccountModal}
        onSubmit={onSubmit}
      />
    </>
  );
}

function EditAccountButton(props: {
  account: Account;
  onSubmit: (account: Account) => void;
}) {
  const [accountVisible, setAccountVisible] = useState(false);

  const showAccountModal = () => {
    setAccountVisible(true);
  };

  const hideAccountModal = () => {
    setAccountVisible(false);
  };

  const onSubmit = (account: Account) => {
    props.onSubmit(account);
    hideAccountModal();
  }

  return (
    <>
      <Button
        shape="circle"
        size="small"
        icon={<EditOutlined />}
        onClick={showAccountModal}
      />
      {accountVisible &&
        <AccountNewForm
          account={props.account}
          visible={accountVisible}
          onFinish={hideAccountModal}
          onSubmit={onSubmit}
        />
      }
    </>
  );
}

function AccountNewForm(props: {
  account?: Account;
  visible: boolean;
  onFinish: () => void;
  onSubmit: (account: Account) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onSubmit = ({
    name,
    marketplace,
    payeeCode,
    validityStartDate,
    disabled,
    disabledDisputeInvoice,
    disputeInvoiceStartDate,
    disputeInvoiceEndDate,
    fee,
  }: {
    name: string,
    marketplace: string,
    payeeCode: string,
    validityStartDate: Dayjs,
    disabled: boolean,
    disabledDisputeInvoice: boolean,
    disputeInvoiceStartDate: Dayjs,
    disputeInvoiceEndDate: Dayjs,
    fee: number,
  }) => {
    props.onSubmit({
      name,
      marketplace,
      payeeCode,
      validityStartDate: validityStartDate.format(apiDateFormat),
      disabled,
      disabledDisputeInvoice,
      disputeInvoiceStartDate: disputeInvoiceStartDate?.format(apiDateFormat),
      disputeInvoiceEndDate: disputeInvoiceEndDate?.format(apiDateFormat),
      fee,
    });
    form.resetFields();
  };

  const onCancel = () => {
    props.onFinish();
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={props.onFinish}
      title="New Account"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form labelCol={{ span: 9 }} wrapperCol={{ span: 16 }} form={form} onFinish={onSubmit}>
        <Form.Item
          labelAlign="left"
          label="Name"
          name="name"
          initialValue={props.account?.name}
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input readOnly={!!props.account} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Payee Code"
          name="payeeCode"
          initialValue={props.account?.payeeCode}
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input readOnly={!!props.account} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Marketplace"
          name="marketplace"
          initialValue={props.account?.marketplace}
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input readOnly={!!props.account} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="ValidityStartDate"
          name="validityStartDate"
          initialValue={props.account ? dayjs(props.account?.validityStartDate, apiDateTimeFormat) : dayjs()}
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <DatePicker format={dateFormat()} inputReadOnly={!!props.account} open={props.account ? false : undefined} allowClear={!props.account} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Disabled"
          name="disabled"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
          initialValue={props.account?.disabled ?? false}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Dispute Invoice Disabled"
          name="disabledDisputeInvoice"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
          initialValue={props.account?.disabledDisputeInvoice ?? false}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Dispute Invoice Start Date"
          name="disputeInvoiceStartDate"
          initialValue={props.account?.disputeInvoiceStartDate ? dayjs(props.account?.disputeInvoiceStartDate, apiDateTimeFormat) : undefined}
        >
          <DatePicker format={dateFormat()} allowClear />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Dispute Invoice End Date"
          name="disputeInvoiceEndDate"
          initialValue={props.account?.disputeInvoiceEndDate ? dayjs(props.account?.disputeInvoiceEndDate, apiDateTimeFormat) : undefined}
        >
          <DatePicker format={dateFormat()} allowClear />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Fee"
          name="fee"
          initialValue={props.account?.fee}
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal >
  );
}